<template>
  <div class="product-filter box1">
    <div class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li>
            <input type="text" class="form-control" v-model="minPrice" readonly />
          </li>
          <li><span>to</span></li>
          <li>
            <input type="text" class="form-control" v-model="price" readonly />
          </li>
        </ul>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height of the product
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#color-filter" role="button" aria-expanded="true"
          aria-controls="color-filter">
          Color/Material Panel
        </h6>
        <div class="wrapper collapse show" id="color-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#noise-filter" role="button" aria-expanded="true"
          aria-controls="noise-filter">
          Noise level (dB)
        </h6>
        <div class="wrapper collapse show" id="noise-filter">
          <VueMultiselect v-model="noise" :options="noiseLevels" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select noises" :searchable="false" selectLabel="" deselectLabel=""
            @input="noiseChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#cutlery-system-filter" role="button"
          aria-expanded="true" aria-controls="cutlery-system-filter">
          Cutlery system
        </h6>
        <div class="wrapper collapse show" id="cutlery-system-filter">
          <VueMultiselect v-model="cutlerySystem" :options="cutlerySystems" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select cutlery systems" :searchable="false" selectLabel=""
            deselectLabel="" @input="cutlerySystemChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#energy-filter" role="button" aria-expanded="true"
          aria-controls="energy-filter">
          Energy efficiency class
        </h6>
        <div class="wrapper collapse show" id="energy-filter">
          <VueMultiselect v-model="energy" :options="energyClasses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select energy efficiencies" :searchable="false" selectLabel=""
            deselectLabel="" @input="energyChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#water-filter" role="button" aria-expanded="true"
          aria-controls="water-filter">
          Water consumption (l)
        </h6>
        <div class="wrapper collapse show" id="water-filter">
          <VueMultiselect v-model="water" :options="waterLevels" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select water consumptions" :searchable="false" selectLabel=""
            deselectLabel="" @input="waterChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
          aria-controls="installation-filter">
          Installation type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <VueMultiselect v-model="installation" :options="instTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
            deselectLabel="" @input="installationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#series-filter" role="button" aria-expanded="true"
          aria-controls="series-filter">
          Product series
        </h6>
        <div class="wrapper collapse show" id="series-filter">
          <VueMultiselect v-model="series" :options="serieses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select serieses" :searchable="false" selectLabel="" deselectLabel=""
            @input="seriesChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feet-filter" role="button" aria-expanded="true"
          aria-controls="feet-filter">
          Adjustable feet
        </h6>
        <div class="wrapper collapse show" id="feet-filter">
          <VueMultiselect v-model="adjustableFeet" :options="adjustableFeets" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select adjustable feets" :searchable="false" selectLabel=""
            deselectLabel="" @input="adjustableFeetChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#program-filter" role="button" aria-expanded="true"
          aria-controls="program-filter">
          List of programs
        </h6>
        <div class="wrapper collapse show" id="program-filter">
          <VueMultiselect v-model="program" :options="programs" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select programs" :searchable="false" selectLabel="" deselectLabel=""
            @input="programChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#special-function-filter" role="button"
          aria-expanded="true" aria-controls="special-function-filter">
          Special functions
        </h6>
        <div class="wrapper collapse show" id="special-function-filter">
          <VueMultiselect v-model="specialFunction" :options="specialFunctions" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select special functions" :searchable="false" selectLabel=""
            deselectLabel="" @input="specialFunctionChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#connectivity-filter" role="button" aria-expanded="true"
          aria-controls="connectivity-filter">
          Connectivity type
        </h6>
        <div class="wrapper collapse show" id="connectivity-filter">
          <VueMultiselect v-model="connectivity" :options="connectivities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select connectivity types" :searchable="false" selectLabel=""
            deselectLabel="" @input="connectivityChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key Features
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="oneHrProgram" id="feature-1" @change="oneHrProgramChanged" />
                <label for="feature-1">1-hour program</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="emotionLight" id="feature-2" @change="emotionLightChanged" />
                <label for="feature-2">emotionLight</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="extraDrying" id="feature-3" @change="extraDryingChanged" />
                <label for="feature-3">Extra drying</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="glass40" id="feature-4" @change="glass40Changed" />
                <label for="feature-4">Glass 40</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="heatexchanger" id="feature-5" @change="heatexchangerChanged" />
                <label for="feature-5">Heatexchanger</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="hygienePlus" id="feature-6" @change="hygienePlusChanged" />
                <label for="feature-6">hygienePlus</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="zeolith" id="feature-7" @change="zeolithChanged" />
                <label for="feature-7">Zeolith®</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="tftDisplay" id="feature-8" @change="tftDisplayChanged" />
                <label for="feature-8">TFT display</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="timeLight" id="feature-9" @change="timeLightChanged" />
                <label for="feature-9">TimeLight</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="variableHinge" id="feature-10" @change="variableHingeChanged" />
                <label for="feature-10">Variable hinge</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="varioDrawer" id="feature-11" @change="varioDrawerChanged" />
                <label for="feature-11">varioDrawer</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="varioSpeedPlus" id="feature-12" @change="varioSpeedPlusChanged" />
                <label for="feature-12">varioSpeed Plus</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="display" id="feature-13" @change="displayChanged" />
                <label for="feature-13">Display</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "DishwasherFilters",
  data: function () {
    return {
      heights: [],
      colors: [],
      noiseLevels: [],
      cutlerySystems: [],
      energyClasses: [],
      waterLevels: [],
      instTypes: [],
      serieses: [],
      widths: [],
      depths: [],
      adjustableFeets: [],
      programs: [],
      specialFunctions: [],
      connectivities: [],
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      color: [],
      cutlerySystem: [],
      height: [],
      noise: [],
      energy: [],
      water: [],
      installation: [],
      series: [],
      width: [],
      depth: [],
      adjustableFeet: [],
      program: [],
      specialFunction: [],
      connectivity: [],
      oneHrProgram: false,
      emotionLight: false,
      extraDrying: false,
      glass40: false,
      heatexchanger: false,
      hygienePlus: false,
      zeolith: false,
      tftDisplay: false,
      timeLight: false,
      variableHinge: false,
      varioDrawer: false,
      varioSpeedPlus: false,
      display: false,
    };
  },
  components: {
    VueMultiselect,
  },

  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    },
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_col_panel",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_energy_class_2017",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyClasses = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_height_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_noise_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.noiseLevels = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_bestecksystem",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.cutlerySystems = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_water_cons",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.waterLevels = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.instTypes = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_feet_adj",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.adjustableFeets = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_list_prgr",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.programs = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_sonderfunktionen",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.specialFunctions = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },

    colorChanged() {
      let filters = {
        attr_col_panel__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    energyChanged() {
      let filters = {
        attr_energy_class_2017__in: _.map(this.energy, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    noiseChanged() {
      let filters = {
        attr_noise_marketing__in: _.map(this.noise, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    waterChanged() {
      let filters = {
        attr_water_cons__in: _.map(this.water, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    heightChanged() {
      let filters = {
        attr_height_marketing__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    cutlerySystemChanged() {
      let filters = {
        attr_bestecksystem__in: _.map(this.cutlerySystem, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    adjustableFeetChanged() {
      let filters = {
        attr_feet_adj__in: _.map(this.adjustableFeet, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    programChanged() {
      let filters = {
        attr_list_prgr__in: _.map(this.program, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    specialFunctionChanged() {
      let filters = {
        attr_sonderfunktionen__in: _.map(this.specialFunction, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    oneHrProgramChanged() {
      let filters = {
        attr_program1hour: this.oneHrProgram ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    emotionLightChanged() {
      let filters = {
        attr_emotionlight: this.emotionLight ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    extraDryingChanged() {
      let filters = {
        attr_extradrying: this.extraDrying ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    glass40Changed() {
      let filters = {
        attr_glass40: this.glass40 ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    heatexchangerChanged() {
      let filters = {
        attr_heatexchanger: this.heatexchanger ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    hygienePlusChanged() {
      let filters = {
        attr_hygieneplus: this.hygienePlus ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    zeolithChanged() {
      let filters = {
        attr_perfect_dry: this.zeolith ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    tftDisplayChanged() {
      let filters = {
        attr_tft: this.tftDisplay ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    timeLightChanged() {
      let filters = {
        attr_timelight: this.timeLight ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    variableHingeChanged() {
      let filters = {
        attr_variohinge: this.variableHinge ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    varioDrawerChanged() {
      let filters = {
        attr_variodrawer: this.varioDrawer ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    varioSpeedPlusChanged() {
      let filters = {
        attr_variospeedplus: this.varioSpeedPlus ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    displayChanged() {
      let filters = {
        attr_indicator_count_down: this.display ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    existingFilters() {
      this.oneHrProgram = this.selectedFilters.attr_program1hour;
      this.emotionLight = this.selectedFilters.attr_emotionlight;
      this.extraDrying = this.selectedFilters.attr_extradrying;
      this.glass40 = this.selectedFilters.attr_glass40;
      this.heatexchanger = this.selectedFilters.attr_heatexchanger;
      this.hygienePlus = this.selectedFilters.attr_hygieneplus;
      this.zeolith = this.selectedFilters.attr_perfect_dry;
      this.tftDisplay = this.selectedFilters.attr_tft;
      this.timeLight = this.selectedFilters.attr_timelight;
      this.variableHinge = this.selectedFilters.attr_variohinge;
      this.varioDrawer = this.selectedFilters.attr_variodrawer;
      this.varioSpeedPlus = this.selectedFilters.attr_variospeedplus;
      this.display = this.selectedFilters.attr_indicator_count_down;

      if (this.selectedFilters.attr_water_cons__in) {
        let sortedWaters = [];
        let unsortedWaters = this.selectedFilters.attr_water_cons__in.split(
          '","'
        );
        unsortedWaters.map((water) => {
          water = water.replaceAll('"', "");
          sortedWaters.push(water);
        });
        this.water = sortedWaters;
      }

      if (this.selectedFilters.attr_noise_marketing__in) {
        let sortedNoises = [];
        let unsortedNoises = this.selectedFilters.attr_noise_marketing__in.split(
          '","'
        );
        unsortedNoises.map((noise) => {
          noise = noise.replaceAll('"', "");
          sortedNoises.push(noise);
        });
        this.noise = sortedNoises;
      }

      if (this.selectedFilters.attr_energy_class_2017__in) {
        let sortedEnergies = [];
        let unsortedEnergies = this.selectedFilters.attr_energy_class_2017__in.split(
          '","'
        );
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', "");
          sortedEnergies.push(energy);
        });
        this.energy = sortedEnergies;
      }

      if (this.selectedFilters.attr_height_marketing__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height_marketing__in.split(
          '","'
        );
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_col_panel__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_panel__in.split(
          '","'
        );
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', "");
          sortedSeries.push(series);
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split(
          '","'
        );
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', "");
          sortedInstallations.push(installation);
        });
        this.installation = sortedInstallations;
      }

      if (this.selectedFilters.attr_bestecksystem__in) {
        let sortedSystems = [];
        let unsortedSystems = this.selectedFilters.attr_bestecksystem__in.split(
          '","'
        );
        unsortedSystems.map((cutlerySystem) => {
          cutlerySystem = cutlerySystem.replaceAll('"', "");
          sortedSystems.push(cutlerySystem);
        });
        this.cutlerySystem = sortedSystems;
      }

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', "");
          sortedWidths.push(width);
        });
        this.width = sortedWidths;
      }
      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }
      if (this.selectedFilters.attr_feet_adj__in) {
        let sortedFeets = [];
        let unsortedFeets = this.selectedFilters.attr_feet_adj__in.split('","');
        unsortedFeets.map((feet) => {
          feet = feet.replaceAll('"', "");
          sortedFeets.push(feet);
        });
        this.adjustableFeet = sortedFeets;
      }
      if (this.selectedFilters.attr_list_prgr__in) {
        let sortedPrograms = [];
        let unsortedPrograms = this.selectedFilters.attr_list_prgr__in.split(
          '","'
        );
        unsortedPrograms.map((program) => {
          program = program.replaceAll('"', "");
          sortedPrograms.push(program);
        });
        this.program = sortedPrograms;
      }
      if (this.selectedFilters.attr_sonderfunktionen__in) {
        let sortedFunctions = [];
        let unsortedFunctions = this.selectedFilters.attr_sonderfunktionen__in.split(
          '","'
        );
        unsortedFunctions.map((func) => {
          func = func.replaceAll('"', "");
          sortedFunctions.push(func);
        });
        this.specialFunction = sortedFunctions;
      }
      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }
    },
  },
};
</script>

<style scoped></style>
