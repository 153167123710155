var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0" }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height of the product ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#color-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "color-filter"
            }
          },
          [_vm._v(" Color/Material Panel ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "color-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#noise-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "noise-filter"
            }
          },
          [_vm._v(" Noise level (dB) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "noise-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.noiseLevels,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select noises",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.noiseChanged },
              model: {
                value: _vm.noise,
                callback: function($$v) {
                  _vm.noise = $$v
                },
                expression: "noise"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#cutlery-system-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "cutlery-system-filter"
            }
          },
          [_vm._v(" Cutlery system ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "cutlery-system-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.cutlerySystems,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select cutlery systems",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.cutlerySystemChanged },
              model: {
                value: _vm.cutlerySystem,
                callback: function($$v) {
                  _vm.cutlerySystem = $$v
                },
                expression: "cutlerySystem"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#energy-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "energy-filter"
            }
          },
          [_vm._v(" Energy efficiency class ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "energy-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energyClasses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy efficiencies",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energyChanged },
              model: {
                value: _vm.energy,
                callback: function($$v) {
                  _vm.energy = $$v
                },
                expression: "energy"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#water-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "water-filter"
            }
          },
          [_vm._v(" Water consumption (l) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "water-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.waterLevels,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select water consumptions",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.waterChanged },
              model: {
                value: _vm.water,
                callback: function($$v) {
                  _vm.water = $$v
                },
                expression: "water"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#installation-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "installation-filter"
            }
          },
          [_vm._v(" Installation type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "installation-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.instTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select installation types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.installationChanged },
              model: {
                value: _vm.installation,
                callback: function($$v) {
                  _vm.installation = $$v
                },
                expression: "installation"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#series-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "series-filter"
            }
          },
          [_vm._v(" Product series ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "series-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.serieses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select serieses",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.seriesChanged },
              model: {
                value: _vm.series,
                callback: function($$v) {
                  _vm.series = $$v
                },
                expression: "series"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feet-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feet-filter"
            }
          },
          [_vm._v(" Adjustable feet ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feet-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.adjustableFeets,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select adjustable feets",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.adjustableFeetChanged },
              model: {
                value: _vm.adjustableFeet,
                callback: function($$v) {
                  _vm.adjustableFeet = $$v
                },
                expression: "adjustableFeet"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#program-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "program-filter"
            }
          },
          [_vm._v(" List of programs ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "program-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.programs,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select programs",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.programChanged },
              model: {
                value: _vm.program,
                callback: function($$v) {
                  _vm.program = $$v
                },
                expression: "program"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#special-function-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "special-function-filter"
            }
          },
          [_vm._v(" Special functions ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "special-function-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.specialFunctions,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select special functions",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.specialFunctionChanged },
              model: {
                value: _vm.specialFunction,
                callback: function($$v) {
                  _vm.specialFunction = $$v
                },
                expression: "specialFunction"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "connectivity-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.connectivities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select connectivity types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.connectivityChanged },
              model: {
                value: _vm.connectivity,
                callback: function($$v) {
                  _vm.connectivity = $$v
                },
                expression: "connectivity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key Features ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oneHrProgram,
                        expression: "oneHrProgram"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.oneHrProgram)
                        ? _vm._i(_vm.oneHrProgram, null) > -1
                        : _vm.oneHrProgram
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.oneHrProgram,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.oneHrProgram = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.oneHrProgram = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.oneHrProgram = $$c
                          }
                        },
                        _vm.oneHrProgramChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("1-hour program")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.emotionLight,
                        expression: "emotionLight"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-2" },
                    domProps: {
                      checked: Array.isArray(_vm.emotionLight)
                        ? _vm._i(_vm.emotionLight, null) > -1
                        : _vm.emotionLight
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.emotionLight,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.emotionLight = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.emotionLight = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.emotionLight = $$c
                          }
                        },
                        _vm.emotionLightChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-2" } }, [
                    _vm._v("emotionLight")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.extraDrying,
                        expression: "extraDrying"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-3" },
                    domProps: {
                      checked: Array.isArray(_vm.extraDrying)
                        ? _vm._i(_vm.extraDrying, null) > -1
                        : _vm.extraDrying
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.extraDrying,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.extraDrying = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.extraDrying = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.extraDrying = $$c
                          }
                        },
                        _vm.extraDryingChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-3" } }, [
                    _vm._v("Extra drying")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.glass40,
                        expression: "glass40"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-4" },
                    domProps: {
                      checked: Array.isArray(_vm.glass40)
                        ? _vm._i(_vm.glass40, null) > -1
                        : _vm.glass40
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.glass40,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.glass40 = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.glass40 = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.glass40 = $$c
                          }
                        },
                        _vm.glass40Changed
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-4" } }, [
                    _vm._v("Glass 40")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.heatexchanger,
                        expression: "heatexchanger"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-5" },
                    domProps: {
                      checked: Array.isArray(_vm.heatexchanger)
                        ? _vm._i(_vm.heatexchanger, null) > -1
                        : _vm.heatexchanger
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.heatexchanger,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.heatexchanger = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.heatexchanger = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.heatexchanger = $$c
                          }
                        },
                        _vm.heatexchangerChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-5" } }, [
                    _vm._v("Heatexchanger")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.hygienePlus,
                        expression: "hygienePlus"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-6" },
                    domProps: {
                      checked: Array.isArray(_vm.hygienePlus)
                        ? _vm._i(_vm.hygienePlus, null) > -1
                        : _vm.hygienePlus
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.hygienePlus,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.hygienePlus = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.hygienePlus = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.hygienePlus = $$c
                          }
                        },
                        _vm.hygienePlusChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-6" } }, [
                    _vm._v("hygienePlus")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.zeolith,
                        expression: "zeolith"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-7" },
                    domProps: {
                      checked: Array.isArray(_vm.zeolith)
                        ? _vm._i(_vm.zeolith, null) > -1
                        : _vm.zeolith
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.zeolith,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.zeolith = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.zeolith = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.zeolith = $$c
                          }
                        },
                        _vm.zeolithChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-7" } }, [
                    _vm._v("Zeolith®")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tftDisplay,
                        expression: "tftDisplay"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-8" },
                    domProps: {
                      checked: Array.isArray(_vm.tftDisplay)
                        ? _vm._i(_vm.tftDisplay, null) > -1
                        : _vm.tftDisplay
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.tftDisplay,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.tftDisplay = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.tftDisplay = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.tftDisplay = $$c
                          }
                        },
                        _vm.tftDisplayChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-8" } }, [
                    _vm._v("TFT display")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.timeLight,
                        expression: "timeLight"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-9" },
                    domProps: {
                      checked: Array.isArray(_vm.timeLight)
                        ? _vm._i(_vm.timeLight, null) > -1
                        : _vm.timeLight
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.timeLight,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.timeLight = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.timeLight = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.timeLight = $$c
                          }
                        },
                        _vm.timeLightChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-9" } }, [
                    _vm._v("TimeLight")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.variableHinge,
                        expression: "variableHinge"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-10" },
                    domProps: {
                      checked: Array.isArray(_vm.variableHinge)
                        ? _vm._i(_vm.variableHinge, null) > -1
                        : _vm.variableHinge
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.variableHinge,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.variableHinge = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.variableHinge = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.variableHinge = $$c
                          }
                        },
                        _vm.variableHingeChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-10" } }, [
                    _vm._v("Variable hinge")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.varioDrawer,
                        expression: "varioDrawer"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-11" },
                    domProps: {
                      checked: Array.isArray(_vm.varioDrawer)
                        ? _vm._i(_vm.varioDrawer, null) > -1
                        : _vm.varioDrawer
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.varioDrawer,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.varioDrawer = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.varioDrawer = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.varioDrawer = $$c
                          }
                        },
                        _vm.varioDrawerChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-11" } }, [
                    _vm._v("varioDrawer")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.varioSpeedPlus,
                        expression: "varioSpeedPlus"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-12" },
                    domProps: {
                      checked: Array.isArray(_vm.varioSpeedPlus)
                        ? _vm._i(_vm.varioSpeedPlus, null) > -1
                        : _vm.varioSpeedPlus
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.varioSpeedPlus,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.varioSpeedPlus = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.varioSpeedPlus = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.varioSpeedPlus = $$c
                          }
                        },
                        _vm.varioSpeedPlusChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-12" } }, [
                    _vm._v("varioSpeed Plus")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.display,
                        expression: "display"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-13" },
                    domProps: {
                      checked: Array.isArray(_vm.display)
                        ? _vm._i(_vm.display, null) > -1
                        : _vm.display
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.display,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.display = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.display = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.display = $$c
                          }
                        },
                        _vm.displayChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-13" } }, [
                    _vm._v("Display")
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }